/* CSS for uh-it */
@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

.not-for-ansatte {
  #main
    .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
  #main input[type="button"]:not(.red),
  #main input[type="submit"]:not(.submit-comment-button):not(.red),
  #main input[type="cancel"]:not(.red),
  #main
    button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red) {
    background-color: @color-primary;
    .transition(all, 0.5s, ease-in);
    &:focus,
    &:hover {
      background-color: @color-primary--dark;
    }
  }
  .header-search {
    top: 18px;
    z-index: 10;
    input[type="text"] {
      background: @color-secondary;
      .placeholderColor(@color-dark, @color-dark) !important;
      color: @color-neutral--dark;
    }
    .header-search-expand,
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
  #head-wrapper {
    background: #ffffff;
    .head-menu .vrtx-login-manage-component .vrtx-dropdown-link {
      background: transparent
        url("../uio1/images/head-dropdown-toggle-black.png") no-repeat scroll
        100% 53%;
    }
    #head {
      .uio-app-name {
        display: none;
      }
      height: 160px;
      .head-menu {
        right: 110px;
        .language {
          display: none;
        }
        a {
          color: @color-neutral--dark;
        }
        .vrtx-dropdown-wrapper ul li a {
          color: @color-link !important;
        }
      }
      #header {
        .head-logo {
          color: @color-primary;
          top: 50px;
          position: absolute;
          font-size: 50px;
          padding: 14px 0px;
        }
      }
    }
  }
}
#globalnav {
  background: @color-primary none repeat scroll 0 0;
  ul li {
    a,
    &.vrtx-active-item a {
      color: @color-light;
      &:hover,
      &:focus {
        background: @color-neutral--light none repeat scroll 0 0;
        color: @color-dark;
        margin-top: 0;
      }
    }
    &.vrtx-active-item a {
      background: @color-light none repeat scroll 0 0;
      color: @color-neutral--dark;
    }
  }
}

#left-main ul.vrtx-breadcrumb-menu li {
  &.vrtx-child a {
    color: @color-dark;
    background-color: @color-neutral--light;
  }
  a {
    &.vrtx-marked {
      background-color: @color-secondary !important;
      color: @color-dark;
      border: none;
    }
    &:hover,
    &:focus {
      color: #009c49;
    }
  }
}

table th {
  font-weight: bold;
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @footer-color;
}

//footer
.not-for-ansatte {
  &:not(.header-context) {
    .responsible-login .content {
      margin-left: 90px;
    }
  }
  #footer-wrapper {
    background-color: @color-secondary;
    *,
    a {
      color: @color-neutral--dark;
    }
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../uio1/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
      &:focus,
      &:hover {
        background-image: url("../uio1/images/dropdown-black-hover.png");
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #nav-offcanvas #lang-link {
      display: none;
    }
    #head-wrapper {
      background: none @color-light;
      height: 177px;
      #head {
        height: 130px;
        #header {
          img {
            padding: 0 10px 10px;
          }
          .head-logo {
            left: 10px;
          }
        }
      }
    }
    #menu-wrapper .menu li.active {
      background: @color-primary none repeat scroll 0 0;
    }
  }
  .menu-search {
    input[type="text"] {
      border-right-width: 7em !important;
      padding-right: 0px;
    }
    button {
      background-color: @color-primary;
      .transition(all, 0.5s, ease-in);
      &:focus,
      &:hover {
        background-color: @color-primary--dark;
      }
    }
  }
}

@media print {
  .not-for-ansatte {
    #head-wrapper {
      display: block;
      width: auto;
      margin: 0 auto;
      #head {
        height: 70px !important;
        #header {
          .head-logo {
            top: 20px;
          }
        }
      }
    }
  }
}

/* Mixin */
.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...
@color-primary: #6e6e6e;
@color-primary--dark: darken(spin(@color-primary, -5), 10%);
@color-secondary: #e4e4e4;
@color-link: #2771bb;

// Neutral colors
@color-neutral--dark: #2b2b2b;
@color-neutral: #949494;
@color-neutral--light: #eaedee;

// Suplementary colors
@color-light: #ffffff;
@color-dark: #000000;
@footer-color: @color-neutral--light;
